/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import CustomizeColors from '../../../partials/customizing/colors.mdx';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "gradient-color-stops",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gradient-color-stops",
    "aria-label": "gradient color stops permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gradient Color Stops"), "\n", React.createElement(_components.p, null, "Utilities for controlling the color stops in background gradients."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "gradientFrom={color}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "--x-gradient-from: {color};"), React.createElement("br"), React.createElement(_components.code, null, "--x-gradient-stops: var(--x-gradient-from), var(--x-gradient-to, transparent);"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "gradientVia={color}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "--x-gradient-stops: var(--x-gradient-from), {color}, var(--x-gradient-to, transparent);"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "gradientTo={color}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "--x-gradient-to: {color};"))))), "\n", React.createElement(_components.h2, {
    id: "starting-color",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starting-color",
    "aria-label": "starting color permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starting color"), "\n", React.createElement(_components.p, null, "Set the starting color of a gradient using the ", React.createElement(_components.code, null, "gradientFrom={color}"), " utilities."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview"
  }, "<>\n  <template preview>\n    <x.div h={24} backgroundImage=\"gradient-to-r\" gradientFrom=\"red-500\" />\n  </template>\n  <x.div backgroundImage=\"gradient-to-r\" gradientFrom=\"red-500\" />\n</>\n")), "\n", React.createElement(_components.p, null, "Gradients fade out to transparent by default."), "\n", React.createElement(_components.h2, {
    id: "ending-color",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ending-color",
    "aria-label": "ending color permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ending color"), "\n", React.createElement(_components.p, null, "Set the ending color of a gradient using the ", React.createElement(_components.code, null, "gradientTo={color}"), " utilities."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview"
  }, "<>\n  <template preview>\n    <x.div\n      h={24}\n      backgroundImage=\"gradient-to-r\"\n      gradientFrom=\"teal-400\"\n      gradientTo=\"blue-500\"\n    />\n  </template>\n  <x.div\n    backgroundImage=\"gradient-to-r\"\n    gradientFrom=\"teal-400\"\n    gradientTo=\"blue-500\"\n  />\n</>\n")), "\n", React.createElement(_components.p, null, "Gradients to ", React.createElement(_components.strong, null, "do not"), " fade in from transparent by default. To fade in from transparent, reverse the gradient direction and use a ", React.createElement(_components.code, null, "gradientFrom={color}"), " utility."), "\n", React.createElement(_components.h2, {
    id: "middle-color",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#middle-color",
    "aria-label": "middle color permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Middle color"), "\n", React.createElement(_components.p, null, "Add a middle color to a gradient using the ", React.createElement(_components.code, null, "gradientVia={color}"), " utilities."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview"
  }, "<>\n  <template preview>\n    <x.div\n      h={24}\n      backgroundImage=\"gradient-to-r\"\n      gradientFrom=\"purple-400\"\n      gradientVia=\"pink-500\"\n      gradientTo=\"red-500\"\n    />\n  </template>\n  <x.div\n    backgroundImage=\"gradient-to-r\"\n    gradientFrom=\"purple-400\"\n    gradientVia=\"pink-500\"\n    gradientTo=\"red-500\"\n  />\n</>\n")), "\n", React.createElement(_components.p, null, "Gradients with a ", React.createElement(_components.code, null, "gradientFrom={color}"), " and ", React.createElement(_components.code, null, "gradientVia={color}"), " will fade out to transparent by default if no ", React.createElement(_components.code, null, "gradientTo={color}"), " is present."), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the gradient color stops of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "gradientFrom={{ md: \"red-500\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "gradientFrom=\"red-500\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div gradientFrom={{ md: 'red-500' }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", "\n", React.createElement(CustomizeColors));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
